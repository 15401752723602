// ** Reducers Imports
import navbar from "./navbar";
import layout from "./layout";
// import auth from './authentication'
import projects from "../views/store/projects";
import taskTypes from "../views/store/tasktypes";
import subTaskTypes from "../views/store/subtasktypes";
import auth from "../views/authentication/store";
import task from '../views/store/task'
import labels from '../views/store/labels'
import members from "../views/store/members";
import sections from "../views/store/sections";
import subTask from "../views/store/subtask";
import settings from "../views/store/settings"

const rootReducer = {
  auth,
  navbar,
  layout,
  projects,
  taskTypes,
  subTaskTypes,
  task,
  labels,
  members,
  sections,
  subTask,
  settings
};

export default rootReducer;
