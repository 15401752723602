//dependency imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

//file imports
import APISvc from "../../../services/api";
import * as endpoints from "../../../constants";
import { convertNumberSkeletonToNumberFormatOptions } from "intl-messageformat-parser";

export const fetchUsersOfCustomer = createAsyncThunk(
    "settings/fetchUsersOfCustomer",
    async (dummy, { getState, dispatch }) => {
        const users = await APISvc.get(`users`)
        const usersData = await users.data.data
        return usersData
    }
)

export const inviteUser = createAsyncThunk(
    "settings/inviteUser",
    async (config, { getState, dispatch }) => {
        console.log("config", config)
        const inviteUser = await APISvc.post("/user/invite", config)
        const users = await APISvc.get(`users/get`)
        const usersData = await users.data.data
        return usersData
    }
)

export const removeUser = createAsyncThunk(
    "settings/removeUser",
    async (id, { getState, dispatch }) => {
        const deletedUser = await APISvc.delete(`/user/delete/${id}`)
        const users = await APISvc.get(`user/${getState().auth.customer_id}`)
        const usersData = await users.data
        return usersData.data
    }
)

export const settingsSlice = createSlice({
    name: "settings",
    initialState: {
        users: {
            loading: true,
            userList: []
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUsersOfCustomer.fulfilled, (state, action) => {
                state.users.loading = false
                console.log(action.payload)
                state.users.userList = action.payload
            })

            .addCase(inviteUser.fulfilled, (state, action) => {
                console.log("payload", action.payload)
                state.users.loading = false
                state.users.userList = action.payload
            })

            .addCase(removeUser.fulfilled, (state, action) => {
                console.log("payload", action.payload)
                state.users.loading = false
                state.users.userList = action.payload
            })
    }
})

export default settingsSlice.reducer