import { store } from "../redux/store";

//auth selectors
export const selectAuth = () => {
  return store.getState().auth;
};

export const selectAuthLoader = () => {
  console.log(store.getState());
  return store.getState().loading;
};

export const findIfUserLoggedIn = () => {
  return store.getState().auth.isLoggedIn
}

export const selectAuthJWT = () => {
  return store.getState().auth.jwt;
};

export const selectAuthValue = () => {
  return store.getState().auth;
};

export const selectAuthCustomerID = () => {
  return store.getState().auth.customer_id;
};

export const selectAuthUserID = () => {
  return store.getState().auth.user_id;
};

//calendar selectors
export const selectCalendar = () => {
  return store.getState().calendar;
};

export const selectStats = () => {
  return store.getState().calendar.stats;
};

export const selectCalendarTags = () => {
  return store.getState().calendar.tags;
};

//calendar tags
export const selectTags = () => {
  return store.getState().tags;
};
