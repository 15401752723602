import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import APIsvc from '../../../services/api.js'
import * as endpoints from '../../../constants'

export const fetchStatusesForProject = createAsyncThunk('store/fetchStatusesForProject', async (args, { dispatch, getState }) => {
    console.log("Inside fetch statuses.")
    const projectId = args
    const response = await APIsvc.get(`/status/${projectId}`)
    const statuses = await response.data.data
    return statuses
})

export const sectionSlice = createSlice({
    name: 'status',
    initialState: {
        projectId: null,
        statuses: [],
        loading: true
    },
    extraReducers: builder => {
        builder
            .addCase(fetchStatusesForProject.fulfilled, (state, action) => {
                state.statuses = action.payload
                state.projectId = action.meta.arg
                state.loading = false
            })
    }
})

export default sectionSlice.reducer
