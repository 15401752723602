import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import APIsvc from '../../../services/api.js'
import * as endpoints from '../../../constants'

export const createProject = createAsyncThunk('store/createProject', async (data, { dispatch, getState }) => {
    const project = await APIsvc.post("/project", data)
    const createdProjectId = await project.data.data
    dispatch(selectProject(createdProjectId))
    const projects = await APIsvc.get("/project")
    const projectList = await projects.data.data
    return projectList
})

export const fetchProjects = createAsyncThunk('store/fetchProjects', async () => {
    const response = await APIsvc.get(`/project`)
    const projects = await response.data.data
    return projects
})

export const projectSlice = createSlice({
    name: 'project',
    initialState: {
        projectList: [],
        selectedProject: null,
        loading: true
    },
    reducers: {
        selectProject: (state, action) => {
            const id = action.payload
            state.selectedProject = state.projectList.filter(project => project.id === id)
        }
    },
    extraReducers: builder => {
        builder
            .addCase(fetchProjects.fulfilled, (state, action) => {
                console.log(action.payload)
                state.projectList = action.payload.map((project) => {
                    return {
                        id: project.id,
                        name: project.name,
                        description: project.description,
                        creatorName: project.user.displayName,
                        creatorId: project.user.uuId
                    }
                })
                state.loading = false
            })
            .addCase(createProject.pending, (state, action) => {
                state.loading = true
            })
            .addCase(createProject.fulfilled, (state, action) => {
                state.projectList = action.payload.map((project) => {
                    return {
                        id: project.id,
                        name: project.name,
                        description: project.description,
                        creatorName: project.user.displayName,
                        creatorId: project.user.uuId
                    }
                })
                state.loading = false
            })
    }
})

export const { selectProject } = projectSlice.actions

export default projectSlice.reducer
