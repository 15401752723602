//dependency imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAuth, onAuthStateChanged } from "firebase/auth";


//file imports
import APISvc from "../../../services/api.js";
import * as endpoints from "../../../constants";
import jwt_decode from "jwt-decode";

export const fetchUserandCustomer = createAsyncThunk(
  "userAuth/fetchUserandCustomer",
  async ({ where, uuid }) => {
    // const response = await APISvc.get(`http://localhost:4900/user/get/${uuid}`)
    const response = await APISvc.get(`${endpoints.GET_USER}${uuid}`);
    const data = response.data;
    console.log(where, data);
    return data;
  }
);

export const createUser = createAsyncThunk(
  "userAuth/createUser",
  async (config) => {
    console.log("inside create user.")
    // console.log('userconfig and customerconfig ', userConfig, customerConfig)
    // const userResponse = await APISvc.post("http://localhost:4900/user/saveuser", userConfig)
    const userCreatuibResponse = await APISvc.post(`/signup`, config);
    const auth = getAuth()
    // console.log("inside fulfilled thunk.")
    const jwt = await auth.currentUser.getIdToken(true)
    const jwt1 = jwt_decode(jwt)
    console.log("decoded jwt", jwt1)
    // const fetchUserResponse = await APISvc.get(``)
    return {
      loading: false,
      isSignUpSuccessful: true,
      jwt,
      email: jwt1.email,
      customer_id: jwt1.customerId,
      user_id: jwt1.user_id,
      displayName: jwt1.displayName
    };
  }
);

export const authSlice = createSlice({
  name: "userAuth",
  initialState: {
    loading: true,
    isSignUpSuccessful: false,
    jwt: "",
    email: "",
    customer_id: "",
    user_id: "",
    username: ""
    // loading: false,
    // isSignUpSuccessful: true,
    // jwt,
    // email: jwt1.email,
    // customer_id: jwt1.customerId,
    // user_id: jwt1.user_id,
    // isOnboardingCompleted: jwt1.isOnboardingCompleted,
    // displayName: jwt1.displayName
  },
  reducers: {
    saveUser: (state, action) => {
      console.log("jwt decoded after sign in", jwt_decode(action.payload));
      const userDetails = action.payload ? jwt_decode(action.payload) : "";
      console.log("userDetails", userDetails);
      state.jwt = action.payload;
      /* eslint-disable  */
      state.customer_id = userDetails.hasOwnProperty("customerId")
        ? userDetails.customerId
        : "";
      state.email = userDetails.email;
      state.user_id = userDetails.user_id;
      state.loading = false;
      state.username = userDetails.displayName
      state.role = userDetails.role
      console.log("end of saveUser.")
    },
    userLogout: (state, action) => {
      console.log(action);
      const userDetails = null;
      console.log("userDetails", userDetails);
      state.jwt = null;
      /* eslint-disable  */
      state.customer_id = null;
      state.email = null;
      state.user_id = null;
      state.loading = false;
      state.role = null
      state.username = ""
      // loading: false,
      // isSignUpSuccessful: true,
      // jwt,
      // email: jwt1.email,
      // customer_id: jwt1.customerId,
      // user_id: jwt1.user_id,
      // isOnboardingCompleted: jwt1.isOnboardingCompleted,
      // displayName: jwt1.displayName
    },
  },
  extraReducers: (builder) => {
    builder

      // .addCase(createUserAndCustomer.fulfilled, (state, action) => {
      //   console.log('inside reducer of createUserAndCustomer', action.payload)
      //   state.value.customer_id = action.payload.customer_id
      //   state.value.user_id = action.payload.user_id
      // })

      .addCase(fetchUserandCustomer.fulfilled, (state, action) => {
        // console.log('inside reducer of getUserandCustomer ', action.payload)
        if (action.payload.Customer.length) {
          state.value.customer_id = action.payload.Customer[0].id;
          state.value.customer_name = action.payload.Customer[0].Name;
        }
        state.value.user_id = action.payload.id;
        state.value.user_name = action.payload.DisplayName;
        console.log("updated user and customer states.");
      })

      .addCase(createUser.fulfilled, (state, action) => {

        console.log("inside extra reducer", action.payload)
        state.isSignUpSuccessful = action.payload.isSignUpSuccessful;
        state.jwt = action.payload.jwt;
        state.customer_id = action.payload.customer_id;
        state.email = action.payload.email;
        state.user_id = action.payload.user_id;
        state.username = action.payload.displayName
        state.role = action.payload.role
        // loading: false,
        // isSignUpSuccessful: true,
        // jwt,
        // email: jwt1.email,
        // customer_id: jwt1.customerId,
        // user_id: jwt1.user_id,
        // isOnboardingCompleted: jwt1.isOnboardingCompleted,
        // displayName: jwt1.displayName
      });
  },
  // addCustomerID: (state, action) => {
  //   state.value.customerID = action.payload
  // },
  // addUserID: (state, action) => {
  //   console.log('inside addUserID.')
  //   state.value.userID = action.payload
  // }
});

// Action creators are generated for each case reducer function
export const { saveUser, userLogout } = authSlice.actions;

export default authSlice.reducer;
