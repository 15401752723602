export const CREATE_PROJECT = '/project/create'

export const GET_ALL_PROJECTS = "/project/getall/";

export const GET_ALL_TASKTYPES = "/tasktype/getall";

export const GET_SUBTASKTYPES = "/subtasktype/getall/";

export const CREATE_CUSTOMER = "/customers/create";

export const GET_USER = "/user/get/";

export const SAVE_USER = "/user/saveuser";

export const CREATE_TASK = '/task/create'

export const GET_ALL_TASKS = '/task/getall/'

export const GET_TASK = '/task/get/'

export const UPDATE_TASK = '/task/update/'

export const GET_LABELS = '/label/get/'

export const GET_MEMBERS = '/member/get/'

export const GET_DEFAULT_SECTIONS = '/section/getalldefault'

export const CREATE_SUGGESTION = '/suggestion/create'

export const GET_ALL_SUGGESTIONS = '/suggestion/getall/'

export const CREATE_MULTIPLE_SUBTASKS = '/subtask/createmany'

export const CREATE_CUSTOM_SUBTASK = '/subtask/create'

export const GET_ALL_SUBTASKS = '/subtask/getall/'

export const GET_SUBTASK = '/subtask/get/'

export const UPDATE_SUBTASK = '/subtask/update/'

export const DELETE_SUBTASK = '/subtask/delete/'