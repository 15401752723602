import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import APIsvc from '../../../services/api.js'
import * as endpoints from '../../../constants'

export const fetchLabels = createAsyncThunk('store/fetchLabels', async (args, { dispatch, getState }) => {
    const response = await APIsvc.get(`/label`)
    const labels = await response.data.data
    return labels
})

export const labelSlice = createSlice({
    name: 'label',
    initialState: {
        labelList: [],
        selectedLabel: [],
        loading: true
    },
    reducers: {
        selectLabel: (state, action) => {
            state.selectedLabel = action.payload
        }
    },
    extraReducers: builder => {
        builder
            .addCase(fetchLabels.fulfilled, (state, action) => {
                state.labelList = action.payload
                state.loading = false
            })
    }
})

export const { selectLabel } = labelSlice.actions

export default labelSlice.reducer
