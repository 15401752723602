import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import APIsvc from '../../../services/api.js'
import * as endpoints from '../../../constants'

export const fetchMembers = createAsyncThunk('store/fetchMembers', async (args, { dispatch, getState }) => {
    const id = 1
    const response = await APIsvc.get(`${endpoints.GET_MEMBERS}${id}`)
    const res = response.data
    return res
})

export const memberSlice = createSlice({
    name: 'member',
    initialState: {
        memberList: [],
        selectedMember: [],
        loading: true
    },
    reducers: {
        selectMember: (state, action) => {
            state.selectedMember = action.payload
        }
    },
    extraReducers: builder => {
        builder
            .addCase(fetchMembers.fulfilled, (state, action) => {
                state.memberList = action.payload.data
                state.loading = false
            })
    }
})

export const { selectMember } = memberSlice.actions

export default memberSlice.reducer
