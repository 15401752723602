//node-module imports
import axios from "axios";
import { selectAuthJWT } from "../selectors";

const base_URL = process.env.REACT_APP_API_BASE_URL;
console.log(base_URL);

const instance = axios.create({
  baseURL: base_URL,
});

instance.interceptors.request.use(
  (conf) => {
    // Adding some information before sending request.
    console.log("Inside Interceptor ", conf);
    console.log("auth jwt in header", selectAuthJWT());
    conf.headers["Authorization"] = `Bearer ${selectAuthJWT()}`;
    return conf;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

export default instance;
