import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import APIsvc from '../../../services/api.js'
import * as endpoints from '../../../constants'
import { fetchSubTasks } from '../task/index.js'

// export const addSuggestion = createAsyncThunk('store/addSuggestion', async (data, { dispatch, getState }) => {
//     const res = data
//     // const response = await APIsvc.post(endpoints.CREATE_SUGGESTION, data)
//     // const res = response.data
//     // await dispatch(fetchSuggestions(data.SubTaskTypeId))
//     console.log(res)
//     return res
// })

export const fetchSuggestions = createAsyncThunk('store/fetchSuggestions', async (args, { dispatch, getState }) => {
    const id = args
    const response = await APIsvc.get(`/tasktemplate/${id}`)
    const res = response.data.data
    console.log(res)
    // const res = x.map(({ id, Lmts, ...suggestion }) => {
    //     return suggestion
    // })
    // console.log(res)
    return res
})

export const createSubtasks = createAsyncThunk('store/createSubtasks', async (args, { dispatch }) => {
    const data = args
    console.log(data)
    const response = await APIsvc.post(`${endpoints.CREATE_MULTIPLE_SUBTASKS}`, data)
    const res = response.data
    console.log(res)
    dispatch(addSuggestion([]))
    return res
})

export const createSubtask = createAsyncThunk('store/createSubtask', async (args, { dispatch }) => {
    const data = args
    console.log(data)
    const response = await APIsvc.post(`${endpoints.CREATE_CUSTOM_SUBTASK}`, data)
    const res = response.data
    await dispatch(fetchSubTasks(args.TaskId))
    console.log(res)
    return res
})

export const updateSubTask = createAsyncThunk('store/updateSubTask', async (args, { dispatch, getState }) => {
    console.log(args)
    const data = args
    const id = args.id
    const response = await APIsvc.patch(`${endpoints.UPDATE_SUBTASK}${id}`, data)
    const res = response.data
    dispatch(fetchSubTasks(args.TaskId))
    // dispatch(fetchSubTask(args.id))
    console.log(res)
    return res
})

// export const fetchSubTask = createAsyncThunk('store/fetchSubTask', async (args) => {
//     const id = args.id
//     const response = await APIsvc.get(`${endpoints.GET_SUBTASK}${id}`)
//     const res = response.data.data
//     console.log(res)
//     const subTask = Object.assign({}, ...res)
//     return subTask
// })
export const deleteSubTask = createAsyncThunk('store/deleteSubTask', async (args, { dispatch }) => {
    const id = args.id
    const response = await APIsvc.delete(`${endpoints.DELETE_SUBTASK}${id}`)
    const res = response.data
    console.log(res)
    await dispatch(fetchSubTasks(args.TaskId))
    return res
})

export const subTaskSlice = createSlice({
    name: 'subTask',
    initialState: {
        suggestionList: [],
        selectedSubTask: null,
        loading: true,
        loadingSubTask: true
    },
    reducers: {
        // selectSubTask: (state, action) => {
        //     state.selectedSubTask = action.payload
        // },
        addSuggestion: (state, action) => {
            state.suggestionList = action.payload
        },
        deleteSuggestion: (state, action) => {
            state.suggestionList = action.payload
        },
        selectSubTask: (state, action) => {
            state.selectedSubTask = action.payload
        }
    },
    extraReducers: builder => {
        builder
            .addCase(fetchSuggestions.fulfilled, (state, action) => {
                state.suggestionList = action.payload
                state.loading = false
            })
        // .addCase(fetchSubTask.fulfilled, (state, action) => {
        //     state.selectedSubTask = action.payload
        //     state.loadingSubTask = false
        // })
    },

})

export const { addSuggestion, selectSubTask } = subTaskSlice.actions

export default subTaskSlice.reducer
