import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import APIsvc from '../../../services/api.js'
import * as endpoints from '../../../constants'

export const fetchTaskTypes = createAsyncThunk('store/fetchTaskTypes', async () => {
    const response = await APIsvc.get(`/tasktype`)
    const tasktypes = await response.data.data
    return tasktypes
})

export const fetchSubTaskTypes = createAsyncThunk('store/fetchSubTaskTypes', async (arg) => {
    console.log("inside fetch subtask types", arg)
    const parentId = arg
    const response = await APIsvc.get(`/tasktype/${parentId}`)
    const subtasktypes = await response.data.data
    return subtasktypes
})

export const taskTypeSlice = createSlice({
    name: 'taskType',
    initialState: {
        taskTypes: [],
        selectedTaskType: null,
        loading: true
    },
    reducers: {
        selectTaskType: (state, action) => {
            console.log("inside select task type")
            state.selectedTaskType = action.payload
            state.loadingTaskType = false
        },
        selectSubtaskType: (state, action) => {
            state.selectedSubtaskType = action.payload
            state.loadingSubtaskType = false
        }
    },
    extraReducers: builder => {
        builder
            .addCase(fetchTaskTypes.fulfilled, (state, action) => {
                console.log(action.payload)
                state.taskTypes = action.payload
                state.loading = false
            })
            .addCase(fetchSubTaskTypes.fulfilled, (state, action) => {
                const subtaskTypes = action.payload
                const parentId = action.meta.arg
                const taskTypeIndex = state.taskTypes.findIndex(type => type.id === parentId)
                console.log(state.taskTypes[taskTypeIndex])
                state.taskTypes[taskTypeIndex] = {
                    ...state.taskTypes[taskTypeIndex],
                    subtaskTypes
                }
                console.log(state.taskTypes[taskTypeIndex])
                state.selectedTaskType = {
                    ...state.taskTypes[taskTypeIndex],
                    subtaskTypes
                }
            })
    }
})

export const { selectTaskType, changeLoading, changeTaskType } = taskTypeSlice.actions

export default taskTypeSlice.reducer
