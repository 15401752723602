import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import APIsvc from '../../../services/api.js'
import * as endpoints from '../../../constants'

export const addTask = createAsyncThunk('store/createTask', async (data, { dispatch, getState }) => {
    const response = await APIsvc.post("/task", data)
    console.log(response)
    const res = response.data
    dispatch(fetchTasks(data.projectId))
    console.log(res)
    const taskId = res.data.id
    // dispatch(fetchTask(taskId))
    return taskId
})

export const fetchTasks = createAsyncThunk('store/fetchTasks', async (args, { dispatch, getState }) => {
    const id = args //projectId
    const response = await APIsvc.get(`/task/${id}`)
    const tasks = response.data.data
    return tasks
})

export const fetchSubTasks = createAsyncThunk('store/fetchSubTasks', async (args) => {
    const id = args
    console.log("inside fetch subtasks.", id)
    const response = await APIsvc.get(`/subtask/${id}`)
    const subtasks = await response.data.data
    return subtasks
})

export const updateTask = createAsyncThunk('store/updateTask', async (args, { dispatch, getState }) => {
    console.log(args)
    const data = args
    const response = await APIsvc.patch(`${endpoints.UPDATE_TASK}${args.id}`, data)
    const res = response.data
    dispatch(fetchTasks(args.ProjectId))
    // dispatch(fetchTask(args.id))
    console.log(res)
    return res
})

export const taskSlice = createSlice({
    name: 'task',
    initialState: {
        taskList: [],
        loading: true,
        createdTaskId: null
    },
    reducers: {
        selectTaskId: (state, action) => {
            console.log("inside selectTaskId")
            state.selectedTaskId = action.payload
        },
        selectTaskType: (state, action) => {
            state.selectedTaskType = action.payload
        }
    },
    extraReducers: builder => {
        builder
            .addCase(addTask.fulfilled, (state, action) => {
                state.createdTaskId = action.payload
            })
            .addCase(fetchTasks.fulfilled, (state, action) => {
                state.taskList = action.payload
                state.loading = false
            })
            .addCase(fetchSubTasks.pending, (state, action) => {
                state.loading = true
            })
            .addCase(fetchSubTasks.fulfilled, (state, action) => {

                if (action.payload.length > 0) {
                    const subtasks = action.payload
                    const parentId = action.meta.arg
                    const taskIndex = state.taskList.findIndex(task => task.id === parentId)
                    state.taskList[taskIndex] = {
                        ...state.taskList[taskIndex],
                        subtasks
                    }
                    state.loading = false
                } else {
                    state.taskList = state.taskList
                }
            })
    }
})

export const { selectTaskId, selectTaskType } = taskSlice.actions

export default taskSlice.reducer
